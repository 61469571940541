import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useMarketingSourcesQuery } from '@/services/cms/marketing-source/marketing-source.service.hook';
import { shuffleArray } from '@/utils/shuffleArray';

import {
  Body,
  Content,
  Divider,
  Footer,
  Header,
  ScrollArea,
  StyledPage,
  Wordmark,
} from './ProjectCheckoutSurveyPage.styles';

function InternalProjectCheckoutSurveyPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isMobile = useIsMobileViewport();

  const { checkoutAnalyticsService } = useServices();
  const marketingSourcesQuery = useMarketingSourcesQuery();
  const marketingSources = useMemo(() => {
    if (!marketingSourcesQuery.data) {
      return [];
    }

    const shuffledArray = shuffleArray(marketingSourcesQuery.data);
    // Other should always be the last item
    return shuffledArray.sort((a, b) => {
      if (a.value === 'other') {
        return 1;
      }
      if (b.value === 'other') {
        return -1;
      }
      return 0;
    });
  }, [marketingSourcesQuery.data]);
  const [selectedMarketingSource, setSelectedMarketingSource] = useState<string | null>(null);

  const onContinue = (skip: boolean) => {
    if (selectedMarketingSource !== null && !skip) {
      checkoutAnalyticsService.onCheckoutSourceFormSubmitted(selectedMarketingSource);
    }

    navigate('/checkout/get-started?' + searchParams.toString());
  };

  return (
    <StyledPage>
      <Header isMobile={isMobile}>
        <Wordmark />
        {isMobile && (
          <button onClick={() => onContinue(true)}>
            <RMText type="sans" size="xs" bold color="primary">
              Skip
            </RMText>
          </button>
        )}
      </Header>
      <Divider />

      <Content>
        <ScrollArea>
          <Body centered={!isMobile}>
            <RMText type="serif" size="xl" color="on-surface-primary">
              One final thing...
            </RMText>
            <RMSpacer spacing={isMobile ? 'xs' : 'sm'} direction="column" />
            <RMText type="sans" size="s" color="on-surface-primary">
              Where did you hear about Remento?
            </RMText>
            <RMSpacer spacing={isMobile ? 'lg' : '2xl'} direction="column" />
            <RMPicker.Root>
              {marketingSources.map((surveyOption) => (
                <RMPicker.Item
                  key={surveyOption.value}
                  label={surveyOption.label}
                  value={surveyOption.value}
                  selected={selectedMarketingSource === surveyOption.value}
                  onClick={() => setSelectedMarketingSource(surveyOption.value)}
                />
              ))}
            </RMPicker.Root>
            <RMSpacer spacing="2xl" direction="column" />
            {!isMobile && (
              <>
                <RMButton
                  background="primary"
                  onClick={() => onContinue(false)}
                  fullWidth
                  disabled={selectedMarketingSource === null}
                >
                  Continue
                </RMButton>
                <RMSpacer spacing="md" direction="column" />
                <RMButton background="none" onClick={() => onContinue(true)} fullWidth>
                  Skip
                </RMButton>
              </>
            )}
          </Body>
        </ScrollArea>

        {isMobile && (
          <Footer>
            <RMButton
              background="primary"
              disabled={selectedMarketingSource === null}
              onClick={() => onContinue(false)}
              fullWidth
            >
              Continue
            </RMButton>
          </Footer>
        )}
      </Content>
    </StyledPage>
  );
}

export function ProjectCheckoutSurveyPage() {
  return (
    <RementoPage type="empty">
      <InternalProjectCheckoutSurveyPage />
    </RementoPage>
  );
}
