import { useMemo } from 'react';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';

import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePeople } from '@/services/api/person';

export interface StorytellerOption {
  label: string;
  value: string;
  personId: string;
  subscriptionOwnerPersonId: string;
}

export function useStorytellerOptions(): StorytellerOption[] | null {
  // Queries
  const user = useUser();
  const personIds = useMemo(() => {
    if (user == null) {
      return null;
    }
    return Array.from(
      new Set(
        user.storytellers
          .map((s) => [s.personId, s.subscriptionOwnerPersonId])
          .flat()
          .concat(user.personId),
      ),
    );
  }, [user]);
  const people = usePeople(personIds);

  // State
  return useMemo<StorytellerOption[] | null>(() => {
    if (user == null) {
      return null;
    }

    const userPerson = people.find((p) => p.refIds.includes(user.personId));
    if (userPerson == null) {
      throw new NotFoundError('person-not-found', {
        origin: 'entity',
        entityType: EntityType.PERSON,
        entityId: user.personId,
      });
    }

    const storytellersMap = new Map<string, StorytellerOption>();

    for (const storyteller of user.storytellers) {
      const storytellerPerson = people.find((p) => p.refIds.includes(storyteller.personId));
      const ownerPerson = people.find((p) => p.refIds.includes(storyteller.subscriptionOwnerPersonId));
      const isOwner = userPerson.refIds.includes(storyteller.subscriptionOwnerPersonId);
      const suffix = userPerson.refIds.includes(storyteller.personId) ? ' (me)' : '';

      let label = `${storytellerPerson?.name?.full}${suffix}`;
      if (isOwner == false) {
        label += ` - owned by ${ownerPerson?.name?.full}`;
      }

      storytellersMap.set(`${storyteller.personId}-${storyteller.subscriptionOwnerPersonId}`, {
        value: `${storyteller.personId}-${storyteller.subscriptionOwnerPersonId}`,
        label,
        personId: storyteller.personId,
        subscriptionOwnerPersonId: storyteller.subscriptionOwnerPersonId,
      });
    }

    return Array.from(storytellersMap.values()).sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }, [people, user]);
}
